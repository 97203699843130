import React, { useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import {
  currentPage,
  getAllDMPosts,
  getAllGroupPosts,
  getAllPosts,
  resetPageNumber,
} from "../features/postSlice";

export const PaginationBlock = () => {
  const { state, pathname } = useLocation();
  const { pageNumber: active, searched, totalPages } = useSelector(
    (state) => state?.posts
  );
  const { allInstitutesIds, profile } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const location = useLocation();
const adminInstitute = profile?.roledata ?.filter((item) => item.role === "admin").map((item) => item?.instituteid);

  useEffect(() => {
    dispatch(resetPageNumber());
  }, [dispatch]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  React.useLayoutEffect(() => {
    const element = document.getElementById("top");
    element?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }, [active]);

  const onClickHandler = (page) => {
console.log("page",page);
    dispatch(currentPage(page));
    if (pathname === "/sendmessage") {
      dispatch(getAllDMPosts({ current_page: page, institutes: allInstitutesIds, adminInstitute }));
    } else if (pathname === "/home") {
      dispatch(
        getAllPosts({
          institutes: allInstitutesIds,
          search: searched,
          current_page: page,
          adminInstitute,
        })
      );
    } else {
      dispatch(
        getAllGroupPosts({ groupid: state?.id, search: searched, current_page: page })
      );
    }
  };

  const renderPaginationItems = () => {
    let items = [];
    const siblingCount = 1; // Number of pages to show on either side of the current page
    const totalShownPages = siblingCount * 2 + 3; // Total number of pages to display including boundaries and ellipsis

    if (totalPages <= totalShownPages) {
      // Show all pages if the total number of pages is small
      for (let i = 1; i <= totalPages; i++) {
        items.push(
          <Pagination.Item
            key={i}
            active={i === active}
            onClick={() => onClickHandler(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      const leftSiblingIndex = Math.max(active - siblingCount, 1);
      const rightSiblingIndex = Math.min(active + siblingCount, totalPages);

      // Always show the first page
      items.push(
        <Pagination.Item
          key={1}
          active={active === 1}
          onClick={() => onClickHandler(1)}
        >
          1
        </Pagination.Item>
      );

      // Show ellipsis if there are hidden pages between the first page and left siblings
      if (leftSiblingIndex > 2) {
        items.push(<Pagination.Ellipsis key="ellipsis-left" />);
      }

      // Show pages between left and right siblings
      for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
        // Avoid adding the first page again
        if (i !== 1 && i !== totalPages) {
          items.push(
            <Pagination.Item
              key={i}
              active={i === active}
              onClick={() => onClickHandler(i)}
            >
              {i}
            </Pagination.Item>
          );
        }
      }

      // Show ellipsis if there are hidden pages between right siblings and the last page
      if (rightSiblingIndex < totalPages - 1) {
        items.push(<Pagination.Ellipsis key="ellipsis-right" />);
      }

      // Always show the last page
      items.push(
        <Pagination.Item
          key={totalPages}
          active={active === totalPages}
          onClick={() => onClickHandler(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return items;
  };

  return totalPages > 1 ? (
    <div className="d-flex justify-content-center">
      <Pagination>
        <Pagination.Prev
          onClick={() => onClickHandler(active - 1)}
          disabled={active === 1}
        />
        {renderPaginationItems()}
        <Pagination.Next
          onClick={() => onClickHandler(active + 1)}
          disabled={active === totalPages}
        />
      </Pagination>
    </div>
  ) : null;
};
