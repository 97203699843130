import Swal from "sweetalert2";
import SchoolAdmin from "../services/admin.service";
import AuthService from "../services/auth.service";
import { AWS_BUCKET_NAME, SUPPORTED_FORMATS } from "./Constants";
import { Note } from "./Alert";


export const upload_file_on_S3 = async (file , setFieldValue , setSubmitting) => {
    setSubmitting(true);
    setFieldValue("fileValidation", file);

    try {
      let url = '';
      let unique_filename = '';
      const res = await SchoolAdmin.generatePresignedUrl({
        "filename": file?.name,
        "contentType": file?.type
      });
      url = res?.data?.data?.url;
      unique_filename = res?.data?.data?.filename;

      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': file?.type
        },
        body: file
      });

      if (response.ok) {
        let path = `https://${AWS_BUCKET_NAME}.s3.amazonaws.com/`;
        setFieldValue("fileuploaded[0]", { path: path + unique_filename, mimetype: file.type, thumbnail: "" });
      } else {
        console.error('Failed to upload image', response.statusText);
      }
    } catch (err) {
      const { message } = err?.response?.data;
      Swal.fire({ text: message ? message : Note.Err, ...Error });
    } finally {
      setSubmitting(false);
    }
  };


  export const upload_thumbnail_on_S3 = async (file, setFieldValue, setSubmitting) => {
    if (file && file.size > 1000000) {
      Swal.fire({ text: "File too large. Max size is 1MB.", icon: 'error' });
      setFieldValue("thumbnailValidation", "");
      setSubmitting(false);
      return;
    }
    setSubmitting(true);
    setFieldValue("thumbnailValidation", file)
    setFieldValue("oldThumbnail", "");

    let url = '';
    let unique_filename = '';
    await SchoolAdmin.generatePresignedUrl({
      "filename": file?.name,
      "contentType": file?.type
    })
      .then((res) => {
        url = res?.data?.data?.url;
        unique_filename = res?.data?.data?.filename;
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        Swal.fire({ text: message ? message : Note.Err, ...Error });
      });

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': file?.type
      },
      body: file
    });
    if (response.ok) {
      let path = `https://${AWS_BUCKET_NAME}.s3.amazonaws.com/`;
      setFieldValue("fileuploaded[0].thumbnail", path + unique_filename);
      setSubmitting(false);
    } else {
      console.error('Failed to upload image', response?.statusText);
    }

  };


export const handleFileChange = async (e, setFieldValue, setSubmitting, setErrormsg) => {
  const file = e?.target?.files[0];
  if (!file) return;
  console.log("file.type",file.type);
  if (!SUPPORTED_FORMATS.includes(file.type)) {
    setErrormsg('Unsupported Format');
    e.target.value = null;
    return;
  }
  const fileSizeInMB = file?.size / (1024 * 1024);
  const response = await AuthService.fileSize();
  const maxSizeInMB = response?.data?.data?.file_size;

  if (fileSizeInMB > maxSizeInMB) {
    e.target.value = null;
    setErrormsg(`File too large (max size ${maxSizeInMB}MB).`);
  } else {
    setErrormsg('');
    await upload_file_on_S3(file, setFieldValue, setSubmitting);
  }
};


export const handleBlurTrim = (handleBlurChange) => (event) => {
  event.target.value = event?.target?.value?.trim();
  handleBlurChange(event);
};


export const TextCharacterLimitShow = (description, limit) => {
  if (description.length > limit) {
    return description.slice(0, limit) + '...';
  }
  return description;
};


export const TrimehandleChange = (setFieldValue) => (field) => (event) => {
  let { value } = event.target;
  value = value.replace(/^\s+/, '');
  value = value.replace(/\s\s+/g, ' ');
  setFieldValue(field, value);
};

export const TrimehandleChangepassword = (setFieldValue) => (field) => (event) => {
  let { value } = event.target;
  value = value.replace(/\s+/g, '');
  setFieldValue(field, value);
};

