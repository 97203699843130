import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { close } from "../../../features/eventSlice";
import { Note, Reminder } from "../../../utils/Alert";
import { dateTimeFormat } from "../../../utils/DateFormat";
import * as Yup from "yup";
import { MESSAGE } from "../../../utils/ValidationMessage";
import { Formik } from "formik";
import { addReminderToPost, getAllDMPosts, getAllGroupPosts, getAllPosts } from "../../../features/postSlice";
import SchoolAdmin from "../../../services/admin.service";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

const filterTime = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);
  return currentDate?.getTime() + 60 * 1000 <= selectedDate?.getTime();
};

const RemindMe = () => {
  const { remindMe, data } = useSelector((state) => state?.event);
  const location = useLocation();
  const state = location?.state;
  const dispatch = useDispatch();
  const handleClose = () => dispatch(close("remindMe"));
  const [notificationCount, setNotificationCount] = useState(0);
  const [data1, setData] = useState([]);
  const { allInstitutesIds, profile } = useSelector((state) => state?.auth);
  const adminInstitute = profile?.roledata?.filter((item) => item?.role === "admin").map((item) => item?.instituteid);
  const {priorityIds} = useSelector(state => state.posts)
  const [isOpen, setIsOpen] = useState(false);
  const notificationRef = useRef(null);
  const bellRef = useRef(null);
  const toggleNotificationList = () => {
    SchoolAdmin.viewNotifications()
      .then((response) => {
        setData(response?.data?.data);
        setIsOpen(true); // Open notification list
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        Swal.fire({ text: message ? message : Note?.Err, ...Error });
      });
  };
  const dismissNotification = (notificationData) => {
    SchoolAdmin.markNotificationSeen({ id: notificationData?.remindId, postid: notificationData?.postid })
      .then((response) => {
        setNotificationCount((prevCount) => prevCount - 1);

        // Remove the dismissed notification from the notification list
        setData((prevData) => prevData.filter((item) => item?.id !== notificationData?.remindId));

        // Close Remind Me modal and reopen the notification list
        handleClose();
        toggleNotificationList();
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        Swal.fire({ text: message ? message : Note?.Err, ...Error });
      });
  };

  const initialValues = {
    remindme: data?.tags?.[0]
      ? (data?.tags[0]?.remind_me
        ? new Date(data?.tags[0].remind_me)
        : new Date(new Date().getTime() + 60 * 1000)
      )
      : (data?.remind_me
        ? new Date(data?.remind_me)
        : new Date(new Date().getTime() + 60 * 1000)
      )
  };

  const validationSchema = Yup.object().shape({
    remindme: Yup.date().required(MESSAGE.REQUIRED),
  });

  const onSubmit = (values) => {
    handleClose();
    dispatch(
      addReminderToPost({
        instituteid: data?.instituteid,
        postid: data?.id,
        time: values?.remindme,
        title: data?.title,
        description: data?.description,
      })
    );
    setTimeout(() =>{
      if (data?.dmpost && data?.realdm) {
        dispatch(getAllDMPosts({ current_page: 1, institutes: allInstitutesIds,priorityIds, adminInstitute }))
      } else {
        if(location?.state?.id){
          dispatch(getAllGroupPosts({ groupid: state?.id, search: "", current_page: 1 }));
        }else{
          dispatch(getAllPosts({ search: "", current_page: 1, institutes: allInstitutesIds, adminInstitute }))
        }
      }
    },100)
  



    // setTimeout(() => {
    //   window.location.reload();
    // },50)


    Swal.fire({ text: "Reminder created", icon: "info", timer: 3000 });
  };

  const handleClickOutside = (event) => {
    if (
      notificationRef?.current &&
      !notificationRef.current.contains(event.target) &&
      bellRef?.current &&
      !bellRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  // useEffect(() => {
  //   document.addEventListener('click', handleClickOutside);

  //   SchoolAdmin.notificationCount()
  //     .then((response) => {
  //       setNotificationCount(response?.data?.count);
  //     })
  //     .catch((err) => {
  //       const { message } = err?.response?.data;
  //       Swal.fire({ text: message ? message : Note?.Err, ...Error });
  //     });

  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, []);

  return (
    <Modal contentClassName="border-0" size="md" centered show={remindMe} onHide={handleClose} keyboard={false}>
      <Modal.Header className="px-4 border-0" closeButton />
      <div className="modal-body pt-0 px-5 ">
        <h4 className="ms-auto event-title text-center mb-4">Remind me at</h4>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ values, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <DatePicker
                placeholderText="Start Date"
                selected={values?.remindme}
                dateFormat={dateTimeFormat}
                className="form-control"
                name="remindme"
                showTimeSelect
                onChange={(date) => setFieldValue(`remindme`, date)}
                minDate={new Date()}
                filterTime={filterTime}
                onChangeRaw={(e) => e.preventDefault()}
                autoComplete="off"
                timeIntervals={1}
              />
              {data?.senderid === -1 ?
                <div className="remindButton" >
                  <button type="submit" className="btn hvr-sweep-to-right my-4">
                    Submit
                  </button>

                  <button type="button" className="btn hvr-sweep-to-right my-4" onClick={() => dismissNotification(data)}>
                    Dismiss
                  </button>
                </div>
                :
                <button type="submit" className="btn full-btn hvr-sweep-to-right my-4">
                  Submit
                </button>
              }
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default RemindMe;












