import React, { useState, useEffect, useMemo } from "react";
import Finance from "../../services/finance.service";
import { Spinner } from "react-bootstrap";
import { renewDateFormat } from "../../utils/DateFormat";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import "./BillingSummary.css"; // Custom CSS file for design improvements

const post = {
  sponsered: "Sponsored Post",
  rsvp_and_pay: "Paid Event",
  pay: "Paid Event",
  renew: "Renewal",
  buy: "Merchandise Sale",
  merchandise: "Merchandise Sale",
};

const BillingSummary = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("all");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const { adminInfo } = useSelector((state) => state?.auth);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await Finance.viewBillingSummary();
        const arr = res?.data?.data?.map((row) => ({
          Date: row?.created_at ? renewDateFormat(row?.created_at) : "NA",
          "Institute Name": row?.institutename,
          "Group": row?.groups?.length
            ? row.groups
              .map(group => {
                const status = group.is_deleted ? " (deleted)" : group.archieve ? " (archived)" : "";
                return `${group.group_name}${status}`;
              })
              .filter(Boolean)
              .join(", ")
            : row?.group_name?.length
              ? row.group_name.filter(Boolean).join(", ")
              : "NA",
          "Member Name": row?.user_name || "NA",
          "Child Name": row?.child_name || "NA",
          "Type": post[row?.type] || row?.type?.replace(/_/g, " "),
          "Description": row?.description?.length > 30 ? row.description.substring(0, 30) + '...' : row?.description,
          "Amount Paid": "$" + row?.amount?.toFixed(2),
          "Confirmation": row?.confirmation,
          "Notes": row?.comment?.length > 30 ? row?.comment.substring(0, 30) + '...' : "NA",
          "Refund Amount": row?.refund_amount !== 0 ? `-$${row?.refund_amount?.toFixed(2)}` : "$0.00",
        }));
        setData(arr);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Sorting logic
  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        const valueA =
          sortConfig.key === "Amount Paid" || sortConfig.key === "Refund Amount"
            ? parseFloat(a[sortConfig.key].replace(/[$,]/g, "")) || 0
            : a[sortConfig.key];
        const valueB =
          sortConfig.key === "Amount Paid" || sortConfig.key === "Refund Amount"
            ? parseFloat(b[sortConfig.key].replace(/[$,]/g, "")) || 0
            : b[sortConfig.key];
  
        if (valueA < valueB) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);
  

  // Function to request sorting on a column
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const filteredData = useMemo(() => {
    if (filter === "all") return sortedData;
    if (filter === "paid") return sortedData.filter((item) => ["Paid Event"].includes(item?.Type));
    if (filter === "merchandise") return sortedData.filter((item) => item?.Type === "Merchandise Sale");
    return sortedData.filter((item) => item?.Type === post[filter]);
  }, [sortedData, filter]);

  // Export to Excel
  const fxSheet = () => {
    const arr = filteredData;
    const header = ["Date", "Institute Name", "Group", "Member Name", "Child Name", "Type", "Post Title", "Amount Paid", "Confirmation", "Notes", "Refund Amount"];
    const compatibleData = arr?.map((row) => {
      const obj = {};
      header.forEach((col) => {
        if (col === "Type") {
          obj[col] = post[row?.Type] || row?.Type?.replace(/_/g, " ");
        } else if (col === "Amount Paid") {
          obj[col] = parseFloat(row["Amount Paid"].replace(/[$,]/g, "")) || 0;
        } else if (col === "Refund Amount") {
          obj[col] = parseFloat(row["Refund Amount"].replace(/[-$,]/g, "")) || 0;
        } else {
          obj[col] = row[col];
        }
      });
      return obj;
    });

    const wb = XLSX.utils.book_new();
    const ws1 = XLSX.utils.json_to_sheet(compatibleData, { header });
    XLSX.utils.book_append_sheet(wb, ws1, "Billing Summary");
    XLSX.writeFile(wb, `Billing_Summary.xlsx`);
  };

  // Render sorting arrow
  const getSortingArrow = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "ascending") {
        return <span> &#9650;</span>; 
      }
      if (sortConfig.direction === "descending") {
        return <span> &#9660;</span>; 
      }
    }
  
    return <span> &#9660;</span>;
  };


  return (
    <div className="wraper-inner bg-grey  space-top">
      <section className="billing-summary pt-4 pb-5 min-box">
        <div className="container billing-wid">
          <div className="similar-shadow-box pb-5">
            <div className="head-btn d-flex justify-content-between">
              <h3 className="inner-title mb-4">Billing Summary</h3>
              <button className="btn black-btn common-btn" onClick={fxSheet}>
                Export to Excel
              </button>
            </div>
            {loading ? (
              <Spinner animation="border" variant="success" />
            ) : (
              <>
                <ul className="nav nav-tabs similer-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link ${filter === "all" && "active"}`} onClick={() => setFilter("all")}>
                      Summary
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link ${filter === "renew" && "active"}`} onClick={() => setFilter("renew")}>
                      Renewals
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link ${filter === "paid" && "active"}`} onClick={() => setFilter("paid")}>
                      Paid Events
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link ${filter === "merchandise" && "active"}`} onClick={() => setFilter("merchandise")}>
                      Merchandise Sale
                    </button>
                  </li>
                </ul>
                <div className="billing-table-wrapper">
                  <div className="billing-table billing-table-bg table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th onClick={() => requestSort("Date")}>
                            Date{getSortingArrow("Date")}
                          </th>
                          <th onClick={() => requestSort("Institute Name")}>
                            Institute Name{getSortingArrow("Institute Name")}
                          </th>
                          <th onClick={() => requestSort("Group")}>
                            Group{getSortingArrow("Group")}
                          </th>
                          <th onClick={() => requestSort("Member Name")}>
                            Member Name{getSortingArrow("Member Name")}
                          </th>
                          <th onClick={() => requestSort("Child Name")}>
                            Child Name{getSortingArrow("Child Name")}
                          </th>
                          <th onClick={() => requestSort("Type")}>
                            Type{getSortingArrow("Type")}
                          </th>
                          <th onClick={() => requestSort("Description")}>
                            Description{getSortingArrow("Description")}
                          </th>
                          <th onClick={() => requestSort("Amount Paid")}>
                            Amount Paid{getSortingArrow("Amount Paid")}
                          </th>
                          <th onClick={() => requestSort("Confirmation")}>
                            Confirmation{getSortingArrow("Confirmation")}
                          </th>
                          <th onClick={() => requestSort("Notes")}>
                            Notes{getSortingArrow("Notes")}
                          </th>
                          <th onClick={() => requestSort("Refund Amount")}>
                            Refund Amount{getSortingArrow("Refund Amount")}
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {filteredData?.map((item, idx) => (
                          <tr key={idx}>
                            <td>{item.Date}</td>
                            <td>{item["Institute Name"]}</td>
                            <td>{item["Group"]}</td>
                            <td>{item["Member Name"]}</td>
                            <td>{item["Child Name"]}</td>
                            <td>{item.Type}</td>
                            <td>{item.Description}</td>
                            <td>{item["Amount Paid"]}</td>
                            <td>{item.Confirmation}</td>
                            <td>{item.Notes}</td>
                            <td>{item["Refund Amount"]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BillingSummary;
