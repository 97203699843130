import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../utils/RoleCheck";

const SelectInstitute = ({ showInstituteM, setShowInstituteM, setReceipient, data, setInstituteGroups, setSelectedGroup, instituteid, institutename, onlyInstitute }) => {
  const [selectedInstitute, setInstitute] = useState({ instituteid, institutename });
  const { profile } = useSelector((state) => state?.auth);

  const handleChange = (row) => setInstitute(row);

  const handleSubmit = () => {
    const selectedInstituteId = selectedInstitute?.instituteid;
  
    // Fetch role information based on the updated institute ID
    const roleInfo = getUserInfo(profile?.roledata, selectedInstituteId)?.role || "";
  if(roleInfo !== "admin" || "teacher"){
    setReceipient("event_type", "standard_event")
    setReceipient("paid_events", false)
  }
    // Set recipients and their roles
    setReceipient("instituteid", selectedInstituteId);
    setReceipient("institutename", selectedInstitute?.institutename);
    setReceipient("added_by", roleInfo); // Use the correct roleInfo
  
    if (onlyInstitute) {
      setReceipient("receiver_id", "");
      setReceipient("receiver_name", "");
      setReceipient("added_by", roleInfo); // Fix role assignment
    } else {
      setReceipient("group_id", []);
      let arr = data
        .filter((group) => group?.instituteid === selectedInstituteId)
        .map((element) => ({ ...element, isChecked: false }));
      setInstituteGroups(arr);
      setSelectedGroup([]);
    }
  
    handleCloseM();
  };
  

  const Institute = ({ name }) => (
    <p className="text-capitalize d-flex align-items-center">
      <i className="ti ti-building"></i> {name}
    </p>
  );

  const handleCloseM = () => setShowInstituteM(false);

  let arr = onlyInstitute ? profile?.roledata : profile?.roledata.filter((row) => row?.role !== "member");
  const content = () => {
    if (arr?.length === 1) {
      return (
        <label className="radio-coustom-design receiver-group select-group radio-box py-1">
          <input className="form-check-input" type="radio" defaultChecked={true} />
          <Institute name={arr[0]?.institutename} />
        </label>
      );
    }
    return arr.map((row) => (
      <label key={row?.instituteid} className="radio-coustom-design receiver-group select-group radio-box py-1">
        <input className="form-check-input" name="institute" type="radio" value={row?.instituteid} checked={selectedInstitute?.instituteid === row?.instituteid} onChange={() => handleChange(row)} />
        <Institute name={row?.institutename} />
      </label>
    ));
  };

  return (
    <Modal contentClassName="border-0" centered show={showInstituteM} onHide={handleCloseM}>
      <Modal.Header className="px-4 border-0" closeButton />
      <div className="modal-body">
        <h4 className="ms-auto event-title text-center mb-4">Select Institute</h4>
        {content()}
        <div className="modal-btn-group mt-4">
          <button type="button" className="btn full-btn hvr-sweep-to-right" data-bs-dismiss="modal" onClick={() => (arr?.length === 1 ? handleCloseM() : handleSubmit())}>
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SelectInstitute;
