import React, { useEffect, useRef, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { supportedVideoFormats } from '../../../utils/Constants';


const PostMedia = ({ data }) => {
  const [show, setShow] = useState(false);
  const [videoStates, setVideoStates] = useState({});
  const videoRefs = useRef([]);
  const modalRef = useRef(null);

  const handleClose = () => setShow(false);

  const isFormatSupported = (mimetype) => {
    const videoElement = document.createElement('video');
    return !!videoElement.canPlayType(mimetype);
  };

  useEffect(() => {
    const observers = [];

    videoRefs.current.forEach((videoRef, index) => {
      if (videoRef) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting && entry.intersectionRatio === 1) {
              const isMuted = videoStates[index]?.isMuted ?? true;
              videoRef.muted = isMuted;

              videoRef.play().catch(error => {
                console.error('Error playing video:', error);
              });
            } else {
              const isMuted = videoRef.muted;
              setVideoStates(prevStates => ({
                ...prevStates,
                [index]: { isMuted }
              }));

              videoRef.pause();
            }
          });
        }, { threshold: 1.0 });

        observer.observe(videoRef);
        observers.push(observer);
      }
    });

    return () => {
      observers.forEach(observer => observer.disconnect());
    };
  }, [videoStates]);

  const handleVideoClick = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement.paused) {
      videoElement.play();
    } else {
      videoElement.pause();
    }
  };

  return Array.isArray(data?.fileuploaded) && data?.fileuploaded?.length
    ? data?.fileuploaded.map((file, i) => {
        if (file?.mimetype?.includes("image")) {
          return (
            <div key={i} className="d-flex align-items-center justify-content-center postmedia mb-3">
              <img src={file?.path} alt="post" onClick={() => setShow(true)} />
              <Modal ref={modalRef} contentClassName="bg-transparent border-0 align-items-center justify-content-center" centered show={show} onHide={handleClose} animation={false}>
                <Modal.Body>
                  <img className="file-media-img" src={file?.path} alt="post" style={{ maxHeight: 800, maxWidth: 800 }} />
                </Modal.Body>
              </Modal>
            </div>
          );
        } else if (supportedVideoFormats.includes(file?.mimetype)) {
          if (isFormatSupported(file?.mimetype)) {
            return (
              <div key={i} className="d-flex align-items-center justify-content-center postmedia mb-3">
                <video
                  controls
                  poster={file?.thumbnail}
                  ref={(el) => videoRefs.current[i] = el}
                  onClick={() => handleVideoClick(i)}
                  muted={videoStates[i]?.isMuted ?? true}
                >
                  <source src={file?.path} type={file?.mimetype} />
                </video>
              </div>
            );
          } else {
            return (
              <div key={i} className="d-flex align-items-center justify-content-center postmedia mb-3">
                <p>Video format not supported. <a href={file?.path} download>Download video</a></p>
              </div>
            );
          }
        }else if (file?.mimetype?.includes("application") || file?.mimetype?.includes("text")) {
          return (
            <a className="mb-2 d-inline-block" key={i} href={file?.path} target={file?.mimetype?.includes("application/pdf") ? "_blank" : ""} rel="noreferrer">
              <i className="ti ti-paperclip"></i> Please find attached
            </a>
          );
        }
      })
    : null;
};

export default PostMedia;
