import { Navigate, useLocation, useRoutes } from "react-router";
import { PublicNavbar, PrivateNavbar, Footer } from "../components/layout";

import Profile from "../pages/schooladmin/institute-profile/profile";
import EditProfile from "../pages/schooladmin/institute-profile/edit-profile";

import { Manage, Create, GroupPosts, EditGroup, ListGroup } from "../pages/schooladmin/groups";

import { ManageMembers, Add, MemberProfile, EditMemberProfile, MemberPayment, ListMember } from "../pages/schooladmin/members";

import Tutorials from "../pages/cms/Tutorials";

import Acronym from "../pages/auth/Acronym";
import OnBoarding from "../pages/auth/Onboarding";
import Verification from "../pages/auth/Verification";
import Verified from "../pages/auth/Verified";
import ChangeTempPassword from "../pages/auth/ChangeTempPassword";
import EmailVerify from "../pages/auth/EmailVerify";
import ResetPassword from "../pages/auth/ResetPassword";

import { roleCheck } from "../utils/RoleCheck";

import MyCalendar from "../pages/schooladmin/my-calendar/MyCalendar";

import CampaignManager from "../pages/campaign/CampaignManager";

import Settings from "../pages/settings/tabs/Settings";
import { ManageAdmin, UpdateRemainder, UpdateRenewal, UpdatePassword, ManageTeacher, ManageClassParent } from "../pages/settings";
import ListSentDM from "../pages/dm/ListSentDM";
import CreateDM from "../pages/dm/CreateDM";
import EditDM from "../pages/dm/EditDM";

import HomePage from "../pages/home/HomePage";
import { useSelector } from "react-redux";
import Loading from "../components/skeleton/Loading";
import CreatePost from "../pages/posts/CreatePost";
import EditPost from "../pages/posts/EditPost";
import { cmsRoutes } from "./Auth";

import Finance from "../pages/finance/List";
import AddFinance from "../pages/finance/Add";
import EditFinance from "../pages/finance/Edit";
import Payment from "../pages/finance/Payment";
import BillingSummary from "../pages/finance/BillingSummary";
import { useEffect } from "react";

const Admin = () => {
  const { pathname } = useLocation();
  const auth = useSelector((state) => state?.auth);
  
  
  let element = useRoutes([
    ...cmsRoutes,
    { path: "/verification-complete/:id", element: <Verification /> },
    { path: "/verified", element: <Verified /> },
    { path: "/emailverified", element: <EmailVerify /> },
    { path: "/reset-password/:token_id", element: <ResetPassword /> },
    { path: "/home", element: <HomePage /> },
    { path: "/profile", element: <Profile /> },
    { path: "/edit-profile", element: <EditProfile /> },
    { path: "/manage-groups", element: roleCheck(["admin"], auth?.logintype) ? <Manage /> : <Navigate to="/home" replace /> },
    { path: "/groups", element: roleCheck(["teacher", "parent"], auth?.logintype) ? <ListGroup /> : <Navigate to="/home" replace /> },
    { path: "/members", element: roleCheck(["teacher", "parent"], auth?.logintype) ? <ListMember /> : <Navigate to="/home" replace /> },
    { path: "/create-groups", element: roleCheck(["admin"], auth?.logintype) ? <Create /> : <Navigate to="/home" replace /> },
    { path: "/edit-group", element: roleCheck(["admin"], auth?.logintype) ? <EditGroup /> : <Navigate to="/home" replace /> },
    { path: "/manage-members", element: roleCheck(["admin"], auth?.logintype) ? <ManageMembers /> : <Navigate to="/home" replace /> },
    { path: "/add-members", element: roleCheck(["admin"], auth?.logintype) ? <Add /> : <Navigate to="/home" replace /> },
    { path: "/member-profile", element: roleCheck(["admin"], auth?.logintype) ? <MemberProfile /> : <Navigate to="/home" replace /> },
    { path: "/edit-member-profile", element: roleCheck(["admin"], auth?.logintype) ? <EditMemberProfile /> : <Navigate to="/home" replace /> },
    { path: "/member-payment", element: <MemberPayment /> },
    { path: "/create-post", element: roleCheck(["admin", "teacher", "parent"], auth?.logintype) ? <CreatePost /> : <Navigate to="/home" replace /> },
    { path: "/edit-post", element: <EditPost /> },
    { path: "/posts", element: <GroupPosts /> },
    { path: "/my-calendar", element: <MyCalendar /> },
    { path: "/sendmessage", element: <ListSentDM /> },
    { path: "/createmessage", element: <CreateDM /> },
    { path: "/editmessage", element: <EditDM /> },
    { path: "/tutorial", element: <Tutorials /> },
    { path: "/campaign-manager", element: <CampaignManager /> },
    { path: "/payment", element: <Payment /> },
    { path: "/finance", element: <Finance /> },
    { path: "/addfinance", element: <AddFinance /> },
    { path: "/editfinance", element: <EditFinance /> },
    { path: "/billing-summary", element: <BillingSummary /> },
    
    {
      path: "/settings",
      element: <Settings />,
      children: [
        { index: true, path: "manage-admin", element: <ManageAdmin /> },
        { path: "update-remainder", element: <UpdateRemainder /> },
        { path: "update-renewal", element: <UpdateRenewal /> },
        { path: "update-password", element: <UpdatePassword /> },
        { path: "manage-teacher", element: <ManageTeacher /> },
        { path: "manage-class-parent", element: <ManageClassParent /> },
      ],
    },
    { path: "/*", element: <Navigate to="/home" replace /> },
  ]);
  let priorAccessibleRoutes = useRoutes([
    ...cmsRoutes,
    { path: "/onboarding", element: <OnBoarding /> },
    { path: "/acronym", element: <Acronym /> },
    { path: "/changepassword", element: <ChangeTempPassword /> },
    { path: "/*", element: <Navigate to={auth.logintype.includes("admin") ? "/acronym" : "/changepassword"} replace /> },
  ]);
 
  return (
    <>
      {auth?.loading ? (
        <Loading />
      ) : auth?.welcomeScreen ? (
        <>
          {pathname === "/emailverified" || pathname?.includes("reset-password") ? <PublicNavbar /> : <PrivateNavbar />}
          {element}
          <Footer />
        </>
      ) : (
        <>
          <PrivateNavbar />
          {priorAccessibleRoutes}
          <Footer />
        </>
      )}
    </>
  );
};

export default Admin;
