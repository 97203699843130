//----------------Build URL----------------------------------
export const BASE = "https://api-test.notetifyapp.com:17365"    
export const Domain = "https://dev-test.notetifyapp.com/home/"

//----------------Git URL------------------------------------
// export const BASE = "https://notetifyapp.devtechnosys.tech:17162"                                   
// export const Domain = "https://notetifyschooladmin.devtechnosys.tech/home"

//----------------Local URL-----------------------------------
// export const BASE = "http://172.16.2.149:17162"
// export const Domain = "http://localhost:3000/"


// export const BASE = "http://172.16.1.250:17162"
// export const Domain = "http://localhost:3000/"

export const BASE_API = BASE + "/api/v1";

export const MOBILE_REGEX = /^[0-9]{10}$/;

export const PASSWORDS_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%^&*?])[A-Za-z\d#$@!%^&*?]{8,}$/g;

export const EMAIL_REGEX =
  /^(?![.+-])([a-zA-Z0-9._+-]+)(?!\.)[a-zA-Z0-9]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/;
  
export const NOSPACE_REGEX = /^(?!\s+$).*/;

export const NOSPACE = "Spaces are not allowed";

export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/gif", "image/webp","video/mp3", "video/mp4",'video/mpeg', "video/x-ms-wmv", "video/quicktime", "video/webm", "application/pdf", "text/plain", "application/msword", "application/vnd.ms-excel", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.openxmlformats-officedocument.presentationml.presentation"];

export const MULTIPLESPACE = /  +/g;

export const CARD_REGEX = /^\d{15,16}$/

export const NODIGITREGEX = /^([^0-9]*)$/

export const DECIMALPATTERN = /^[0-9]+(\.[0-9]{1,2})?$/;

export const AWS_BUCKET_NAME = "dev-files-new";

export const supportedVideoFormats = ['video/mp4','video/x-ms-wmv','video/quicktime','video/webm','video/x-msvideo','video/mpeg','video/3gpp','video/3gpp2','video/x-matroska','video/ogg','video/x-flv','video/MP2T','application/vnd.apple.mpegurl','application/x-mpegurl'];



// "homepage": "https://notetifyschooladmin.devtechnosys.tech",