import React from "react";
import { ErrorMessage, Formik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import SchoolAdmin from "../../../services/admin.service";
import { MESSAGE } from "../../../utils/ValidationMessage";
import { MOBILE_REGEX, NOSPACE} from "../../../utils/Constants"
import { Error, Note, Success } from "../../../utils/Alert";

const AddAdmin = ({ refresh, setRefresh }) => {
  let initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phoneno: "",
    group_id: [],
    role: "admin",
    notification: true,
    child_exists: false,
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().strict(true).trim(NOSPACE).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED),
    lastname: Yup.string().max(30, "Last name should be less than 30 chars").required(MESSAGE.REQUIRED).trim(NOSPACE),
    email: Yup.string().email(MESSAGE.EMAIL).max(255).required(MESSAGE.REQUIRED),
    phoneno: Yup.string().matches(MOBILE_REGEX, MESSAGE.PHONE).required(MESSAGE.REQUIRED),
    // group_id: Yup.array().required().min(1, "Must be a member of atleast one group"),
  });

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    SchoolAdmin.addMember(values)
      .then((res) => {
        Swal.fire({ text: Note.AdminCreated, ...Success });
        setRefresh(!refresh);
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        Swal.fire({ text: message ? message : Note.Err, ...Error });
        console.error(err);
        resetForm();
        setSubmitting(false);
      });
  };

  return (
    <>
      <div className="modal fade common-modal" id="add-new-admin" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-head text-center">
              <h5 className="modal-title" id="exampleModalLabel">
                Add New Admin
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="ti ti-x"></i>
              </button>
            </div>
            <div className="modal-body">
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ values, handleChange, handleBlur, isSubmitting, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <label className="custom-field one mb-3 without-icon">
                      <input type="text" placeholder=" " name="firstname" onChange={handleChange} onBlur={handleBlur} value={values?.firstname} />
                      <span className="placeholder">First Name</span>
                      <span className="text-danger">
                        <ErrorMessage name="firstname" />
                      </span>
                    </label>
                    <label className="custom-field one mb-3 without-icon">
                      <input type="text" placeholder=" " name="lastname" onChange={handleChange} onBlur={handleBlur} value={values?.lastname} />
                      <span className="placeholder">Last Name</span>
                      <span className="text-danger">
                        <ErrorMessage name="lastname" />
                      </span>
                    </label>
                    <label className="custom-field one mb-3 without-icon">
                      <input type="text" placeholder=" " name="email" onChange={handleChange} onBlur={handleBlur} value={values?.email} />
                      <span className="placeholder">Email</span>
                      <span className="text-danger">
                        <ErrorMessage name="email" />
                      </span>
                    </label>
                    <label className="custom-field one mb-3 without-icon">
                      <input type="text" placeholder=" " name="phoneno" onChange={handleChange} onBlur={handleBlur} value={values?.phoneno} />
                      <span className="placeholder">Phone Number</span>
                      <span className="text-danger">
                        <ErrorMessage name="phoneno" />
                      </span>
                    </label>
                    <div className="btn-box mt-4">
                      <button type="submit" data-bs-toggle="modal" data-bs-target="#reminder-sucess" className="btn full-btn hvr-sweep-to-right">
                        Add
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAdmin;
