import React, { useState } from "react";
import { Formik, Field } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Swal from "sweetalert2";
import AuthService from "../../services/auth.service";
import { EMAIL_REGEX, MOBILE_REGEX, NOSPACE_REGEX, PASSWORDS_REGEX } from "../../utils/Constants";
import { MESSAGE } from "../../utils/ValidationMessage";
import { States } from "../../utils/Options";
import { complexEmailRegex } from "../../utils/EmailCheck";
import { Error, Note, Success } from "../../utils/Alert";

const Register = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  let initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmpassword: "",
    institutename: "",
    phoneno: "",
    streetAddress: "",
    city: "",
    state: "",
    zipcode: "",
    accept: false,
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().strict(true).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    lastname: Yup.string().strict(true).max(30, "Last name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
   email : Yup.string()
      .max(75)
      .required(MESSAGE.REQUIRED)
      .email(MESSAGE.EMAIL)
      .matches(EMAIL_REGEX, MESSAGE.EMAIL),
    password: Yup.string().required(MESSAGE.REQUIRED).matches(PASSWORDS_REGEX, MESSAGE.PASSWORD),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords do not match")
      .required(MESSAGE.REQUIRED),
    institutename: Yup.string().strict(true).required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE).max(30),
    phoneno: Yup.string().required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE).matches(MOBILE_REGEX, MESSAGE.PHONE),
    streetAddress: Yup.string().strict(true).max(255, "Street address should be less than 255 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    city: Yup.string().strict(true).max(255, "City should be less than 255 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    state: Yup.string().required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    zipcode: Yup.string()
  .required(MESSAGE.REQUIRED)
  .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
  .matches(/^[0-9]{5}$/, "Invalid zip code")
  .test("not-all-zeros", "Zip code cannot be 00000", (value) => value !== "00000"),

    accept: Yup.bool().oneOf([true], "Please accept Terms & Conditions and Privacy Policy"),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    Object?.keys(values)?.forEach((k) => (typeof values[k] === "string" ? (values[k] = values[k]?.trim().replace(/  +/g, " ")) : values[k]));
    try {
      const { accept, confirmpassword, ...rest } = values;
      const response = await AuthService.register(rest);
      Swal.fire({ text: response?.data?.message, ...Success });
      if (response?.data?.data?.key === "updated") {
        navigate("/login");
      } else {
        navigate("/verified", { state: { email: values?.email } });
      }
    } catch (err) {
      const { message } = err?.response?.data;
      if (message) {
        Swal.fire({ text: message, ...Error });
      } else {
        Swal.fire({ text: Note.Err, ...Error });
      }
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="wraper-inner bg-banner non-height space-top">
        <section className="home-registration">
          <div className="container">
            <div className="row  justify-content-center">
              <div className="col-md-5 m-auto">
                <div className="similar-shadow-box">
                  <div className="form-head">
                    <h2>Registration</h2>
                  </div>
                  <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                          <label className="custom-field one">
                            <input type="text" name="firstname" placeholder="First Name" onChange={handleChange} onBlur={handleBlur} value={values?.firstname} />
                            <i className="ti ti-user"></i>
                            <span className="text-danger">{touched?.firstname && errors?.firstname}</span>
                          </label>
                        </div>
                        <div className="mb-4">
                          <label className="custom-field one">
                            <input type="text" name="lastname" placeholder="Last Name" onChange={handleChange} onBlur={handleBlur} value={values?.lastname} />
                            <i className="ti ti-user"></i>
                            <span className="text-danger">{touched?.lastname && errors?.lastname}</span>
                          </label>
                        </div>
                        <div className="mb-4">
                          <label className="custom-field one">
                            <input type="email" name="email" placeholder="Email Address" onChange={handleChange} onBlur={handleBlur} value={values?.email} />
                            <i className="ti ti-mail"></i>
                            <span className="text-danger">{touched?.email && errors?.email}</span>
                          </label>
                        </div>
                        <div className="mb-4">
                          <label className="custom-field one">
                            <input type="phone" name="phoneno" placeholder="Mobile Number" onChange={handleChange} onBlur={handleBlur} value={values?.phoneno} />
                            <i className="ti ti-phone"></i>
                            <span className="text-danger">{touched?.phoneno && errors?.phoneno}</span>
                          </label>
                        </div>
                        <div className="mb-4">
                          <label className="custom-field one">
                            <input  type={showPassword ? "text" : "password"} name="password" placeholder="Password" onChange={handleChange} onBlur={handleBlur} value={values?.password} />
                            <i className="ti ti-lock"></i>
                            <i className={`ti ${showPassword ? "ti-eye" : "ti-eye-off"}`} onClick={() => setShowPassword(!showPassword)}></i>
                            <span className="text-danger">{touched?.password && errors?.password}</span>
                          </label>
                        </div>
                        <div className="mb-4">
                          <label className="custom-field one">
                            <input type={showConfirmPassword ? "text" : "password"} name="confirmpassword" placeholder="Confirm Password" onChange={handleChange} onBlur={handleBlur} value={values?.confirmpassword} />
                            <i className="ti ti-lock"></i>
                            
                             <i className={`ti ${showConfirmPassword ? "ti-eye" : "ti-eye-off"}`} onClick={() => setShowConfirmPassword(!showConfirmPassword)}></i>

                            <span className="text-danger">{touched?.confirmpassword && errors?.confirmpassword}</span>
                          </label>
                        </div>
                        <div className="mb-4">
                          <label className="custom-field one">
                            <input type="text" name="institutename" maxLength={30} placeholder="Institute Name" onChange={(e) => {
                              if (e.target.value.length <= 30) {
                                handleChange(e);
                              }
                            }} onBlur={handleBlur} value={values?.institutename} />
                            <i className="ti ti-building"></i>
                            <span className="text-danger">{touched?.institutename && errors?.institutename}</span>
                          </label>
                        </div>
                        <div className="mb-4">
                          <label className="custom-field one">
                            <input type="text" name="streetAddress" placeholder="Street Address" onChange={handleChange} onBlur={handleBlur} value={values?.streetAddress} />
                            <i className="ti ti-map-pin"></i>
                            <span className="text-danger">{touched?.streetAddress && errors?.streetAddress}</span>
                          </label>
                        </div>
                        <div className="mb-4">
                          <label className="custom-field one">
                            <input type="text" name="city" placeholder="City" onChange={handleChange} onBlur={handleBlur} value={values?.city} />
                            <i className="ti ti-map-pin"></i>
                            <span className="text-danger">{touched?.city && errors?.city}</span>
                          </label>
                        </div>
                        <div className="mb-4">
                          <label className="custom-field one">
                            <i className="ti ti-map-pin"></i>
                            <select className={`form-control ${values?.state ? "selected-text" : ""}`} name="state" type="text" onChange={handleChange} onBlur={handleBlur}>
                              {States.map((item, index) => (
                                <option key={index} value={item?.value}>
                                  {item?.label}
                                </option>
                              ))}
                            </select>
                          </label>
                          <span className="text-danger custom-field">{touched?.state && errors?.state}</span>
                        </div>
                        <div className="mb-4">
                          <label className="custom-field one">
                            <input type="text" name="zipcode" placeholder="Zip Code" onChange={handleChange} onBlur={handleBlur} value={values?.zipcode} />
                            <i className="ti ti-map-2" />
                            <span className="text-danger">{touched?.zipcode && errors?.zipcode}</span>
                          </label>
                        </div>
                        <div className="mb-1 terms-condition-box d-flex">
                          <label className="coustom-checkbox ">
                            <Field type="checkbox" name="accept" checked={values?.accept} />
                            <span className="checkmark"></span>
                            <p className="title-lable">
                              Accept
                              <Link to="/termsandcondition" target="_blank" className="blue-link ms-1 me-1">
                                Terms & Conditions
                              </Link>
                              and
                            </p>
                          </label>

                          <p className="title-lable-sec">
                            <Link to="/privacypolicy" target="_blank" className="blue-link ms-1">
                              Privacy Policy
                            </Link>
                          </p>
                        </div>
                        <div className="mb-3">
                          <span className="text-danger">{touched?.accept && errors?.accept}</span>
                        </div>
                        <div className="mb-3 ">
                          <button type="submit" className="btn full-btn hvr-sweep-to-right" disabled={isSubmitting}>
                            Register
                          </button>
                        </div>
                        <span className="bottom-text text-center d-flex justify-content-center  align-items-center mt-4">
                          Already have account?
                          <Link to="/login" className="blue-link ms-1">
                            Sign In
                          </Link>
                        </span>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Register;
