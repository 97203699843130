import { Error, Info, Note, Success } from "../utils/Alert";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

export const showPostError = (user,navigate) => {

console.log("user",user);

  if(user?.stripe_account_id==null || user?.stripe_account_id==""){

    let msg=Note.EnableFinancialInfo;
    if(user?.role=='teacher'){
      msg=Note.EnableFinancialInfo;
    }

    Swal.fire({ text: msg, ...Error });

    if(user.role=='admin'){
      setTimeout(() => {
        navigate("/finance");
      }, 3000);
    }
  
  
    return true;
  }

  if(!user?.accept_payment){

    let msg=Note.CompleteFinancialInfoAdmin;
    if(user?.role=='teacher' ){
      msg=Note.CompleteFinancialInfoUser;
    }

    Swal.fire({ text: msg, ...Error });
    return true;
  }

  return false;

};